<template>
  <div class="employment-tests animated fadeIn">
    <v-col cols="12">
      <div class="pa-5">
        <v-row class="align-items-center mb-1">
          <v-col>
            <h5>
              <v-icon class="me-1 primary--text" small>mdi-circle</v-icon>
              تست‌های کارمند
            </h5>
          </v-col>
          <v-col class="text-end"
            ><v-btn class="primary-btn" height="50" @click="addTest()">
              <v-icon class="me-1">mdi-plus</v-icon>
              <h6>افزودن تست جدید</h6>
            </v-btn></v-col
          >
        </v-row>
        <h6 class="red--text mb-2">
          <v-icon class="me-1 red--text">mdi-alert-circle-outline</v-icon>وارد
          کردن عنوان تست الزامی است.
        </h6>
        <b-table
          responsive
          show-empty
          :fields="Fields"
          :items="tests"
          empty-text="موردی برای نمایش وجود ندارد"
          empty-filtered-text="موردی برای نمایش وجود ندارد"
          :filter="Filter"
          :current-page="CurrentPage"
          :per-page="PerPage"
          tbody-tr-class="trClass"
          :busy="testsBusy"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(title)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-text-field
                v-model="data.item.title"
                outlined
                dense
                type="text"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:cell(examResult)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-text-field
                v-model="data.item.examResult"
                outlined
                dense
                type="text"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:cell(date)="data">
            <div style="text-align: center; vertical-align: middle">
              <span :id="`testDate${data.index}`">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="data.item.date"
                  class="date-input"
                  :editable="true"
                  readonly
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="data.item.date"
                :element="`testDate${data.index}`"
                color="#00a7b7"
              />
            </div>
          </template>
          <template v-slot:cell(fileId)="data">
            <div style="text-align: center; vertical-align: middle">
              <div v-if="data.item.fileUrl">
                <a
                  class="v-btn purple-btn ps-5 pe-5 py-0"
                  :href="data.item.fileUrl"
                  target="_blank"
                >
                  <v-icon class="me-1 white--text">mdi-eye-outline</v-icon>
                  <span>مشاهده فایل</span>
                </a>
              </div>
              <div v-else>
                <v-btn
                  v-if="!isUploading[finalIndex(data.index)]"
                  class="orange-btn py-3"
                  width="140"
                  @click="
                    $refs[
                      `testFile${finalIndex(data.index)}`
                    ].$refs.input.click()
                  "
                >
                  <v-icon class="me-1 white--text">mdi-upload-outline</v-icon>
                  <span>آپلود فایل</span></v-btn
                >
                <v-file-input
                  @change="uploadTestFile(file, finalIndex(data.index))"
                  v-model="file"
                  :ref="`testFile${finalIndex(data.index)}`"
                  outlined
                  dense
                  v-show="false"
                ></v-file-input>
                <div v-if="myFile[finalIndex(data.index)].showProgress">
                  <v-progress-linear
                    rounded
                    height="10"
                    :value="myFile[finalIndex(data.index)].uploadPercentage"
                  >
                  </v-progress-linear>
                  <h6 class="mt-2">در حال بارگذاری...</h6>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:cell(operation)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-btn
                class="red-btn py-3"
                @click="removeTestItem(finalIndex(data.index))"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
            </div>
          </template>
          <div slot="table-busy" class="text-center primary--text my-2">
            <v-progress-circular
              indeterminate
              color="primary"
              class="align-middle"
            ></v-progress-circular>
            <h6 class="mt-4 grey--text text--darken-4">در حال بارگذاری...</h6>
          </div>
        </b-table>
        <v-pagination
          v-model="CurrentPage"
          :length="Math.ceil(tests.length / PerPage)"
          :total-visible="5"
          prev-icon="arrow_back"
          next-icon="arrow_forward"
          style="float: center"
        ></v-pagination>
        <v-row class="mt-4">
          <v-col>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select
          ></v-col>
          <v-col class="text-end">
            <v-btn
              class="green-btn"
              width="180"
              height="50"
              @click="saveData('testsLoading')"
              :loading="testLoading"
              v-if="tests.length || hasTests"
              :disabled="computedDisabledForTests"
            >
              <v-icon class="me-1">mdi-check-underline</v-icon>
              <h6>ذخیره‌سازی تغییرات</h6>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <hr class="my-4" />
      <div class="pa-5">
        <v-row class="align-items-center mb-1">
          <v-col>
            <h5>
              <v-icon class="me-1 primary--text" small>mdi-circle</v-icon>

              فایل‌های مربوطه
            </h5>
          </v-col>
          <v-col class="text-end"
            ><v-btn class="primary-btn" height="50" @click="addFile()">
              <v-icon class="me-1">mdi-plus</v-icon>
              <h6>افزودن فایل جدید</h6>
            </v-btn></v-col
          >
        </v-row>
        <h6 class="red--text mb-2">
          <v-icon class="me-1 red--text">mdi-alert-circle-outline</v-icon>وارد
          کردن عنوان فایل و آپلود آن الزامی است.
        </h6>
        <b-table
          responsive
          show-empty
          :fields="fileFields"
          :items="testsFiles"
          empty-text="موردی برای نمایش وجود ندارد"
          empty-filtered-text="موردی برای نمایش وجود ندارد"
          :current-page="fileCurrentPage"
          :per-page="filePerPage"
          tbody-tr-class="trClass"
          :busy="filesBusy"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(title)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-text-field
                v-model="data.item.title"
                outlined
                dense
                type="text"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:cell(description)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-text-field
                v-model="data.item.description"
                outlined
                dense
                type="text"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:cell(fileId)="data">
            <div style="text-align: center; vertical-align: middle">
              <div v-if="data.item.fileUrl">
                <a
                  class="v-btn purple-btn ps-5 pe-5 py-0"
                  :href="data.item.fileUrl"
                  target="_blank"
                >
                  <v-icon class="me-1 white--text">mdi-eye-outline</v-icon>
                  <span>مشاهده فایل</span>
                </a>
              </div>
              <div v-else>
                <v-btn
                  v-if="!isFileUploading[fileFinalIndex(data.index)]"
                  class="orange-btn py-3"
                  width="140"
                  @click="
                    $refs[
                      `File${fileFinalIndex(data.index)}`
                    ].$refs.input.click()
                  "
                >
                  <v-icon class="me-1 white--text">mdi-upload-outline</v-icon>
                  <span>آپلود فایل</span></v-btn
                >
                <v-file-input
                  @change="uploadFile(file, fileFinalIndex(data.index))"
                  v-model="file"
                  :ref="`File${fileFinalIndex(data.index)}`"
                  outlined
                  dense
                  v-show="false"
                ></v-file-input>
                <div v-if="fileMyFile[fileFinalIndex(data.index)].showProgress">
                  <v-progress-linear
                    rounded
                    height="10"
                    :value="
                      fileMyFile[fileFinalIndex(data.index)].uploadPercentage
                    "
                    class="mb-4 mt-0"
                  >
                  </v-progress-linear>
                  <h6 class="mt-2">در حال بارگذاری...</h6>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:cell(operation)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-btn
                class="red-btn py-3"
                @click="removeFileItem(fileFinalIndex(data.index))"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.index + filePerPage * (fileCurrentPage - 1) + 1 }}
            </div>
          </template>
          <div slot="table-busy" class="text-center primary--text my-2">
            <v-progress-circular
              indeterminate
              color="primary"
              class="align-middle"
            ></v-progress-circular>
            <h6 class="mt-4 grey--text text--darken-4">در حال بارگذاری...</h6>
          </div>
        </b-table>
        <v-pagination
          v-model="fileCurrentPage"
          :length="Math.ceil(testsFiles.length / filePerPage)"
          :total-visible="5"
          prev-icon="arrow_back"
          next-icon="arrow_forward"
          style="float: center"
        ></v-pagination>
        <v-row class="mt-4">
          <v-col>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="filePerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select
          ></v-col>
          <v-col class="text-end">
            <v-btn
              class="green-btn"
              width="180"
              height="50"
              @click="saveData('filesLoading')"
              :loading="filesLoading"
              v-if="testsFiles.length || hasFiles"
              :disabled="computedDisabledForFiles"
            >
              <v-icon class="me-1">mdi-check-underline</v-icon>
              <h6>ذخیره‌سازی تغییرات</h6>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  props: ["userData"],
  components: { datePicker: VuePersianDatetimePicker },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 5,
      fileCurrentPage: 1,
      filePerPage: 5,
      perPageOptions: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
      ],
      Filter: "",
      Fields: [
        { key: "index", label: "#" },
        { key: "title", label: "عنوان تست" },
        { key: "date", label: "تاریخ تست" },
        { key: "examResult", label: "نتیجه تست" },
        { key: "fileId", label: "فایل" },
        { key: "operation", label: "عملیات" },
      ],
      fileFields: [
        { key: "index", label: "#" },
        { key: "title", label: "عنوان فایل" },
        { key: "description", label: "توضیحات" },
        { key: "fileId", label: "فایل" },
        { key: "operation", label: "عملیات" },
      ],
      moment: moment,
      file: [],
      myFile: [],
      fileMyFile: [],
      testLoading: false,
      filesLoading: false,
      isUploading: [],
      isFileUploading: [],
      tests: [],
      testsFiles: [],
      testsBusy: false,
      filesBusy: false,
      reactivity: false,
      hasTests: false,
      hasFiles: false,
    };
  },
  methods: {
    addTest() {
      this.tests.push({
        title: "",
        date: "",
        examResult: "",
        fileId: "",
        fileUrl: "",
      });
      this.myFile.push({
        isUploaded: false,
        uploadPercentage: 0,
        uploading: false,
      });
      this.isUploading.push(false);
    },
    removeTestItem(index) {
      this.tests.splice(index, 1);
    },
    addFile() {
      this.testsFiles.push({
        title: "",
        description: "",
        fileId: "",
        fileUrl: "",
      });
      this.fileMyFile.push({
        isUploaded: false,
        uploadPercentage: 0,
        uploading: false,
      });
      this.isFileUploading.push(false);
    },
    removeFileItem(index) {
      this.testsFiles.splice(index, 1);
    },
    finalIndex(index) {
      return index + this.PerPage * (this.CurrentPage - 1);
    },
    fileFinalIndex(index) {
      return index + this.filePerPage * (this.fileCurrentPage - 1);
    },
    getData() {
      this.testsBusy = true;
      this.filesBusy = true;
      //NOTE getting doc infos when page mount
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/examinations",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.tests = res.data.examinations;
            this.testsFiles = res.data.files;
            if (this.tests.length) {
              this.hasTests = true;
            }
            if (this.testsFiles.length) {
              this.hasFiles = true;
            }
            this.tests.forEach((item) => {
              this.myFile.push({
                isUploaded: false,
                uploadPercentage: 0,
                uploading: false,
              });
            });
            this.testsFiles.forEach((item) => {
              this.fileMyFile.push({
                isUploaded: false,
                uploadPercentage: 0,
                uploading: false,
              });
            });
            this.tests.forEach((item) => {
              this.isUploading.push(false);
            });
            this.testsFiles.forEach((item) => {
              this.isFileUploading.push(false);
            });
            this.testsBusy = false;
            this.filesBusy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.testsBusy = false;
            this.filesBusy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.testsBusy = false;
          this.filesBusy = false;
        });
    },
    saveData(loading) {
      this[loading] = true;
      this.tests.forEach((e, i) => {
        !e.fileId ? delete this.tests[i].fileId : "";
      });
      this.tests.forEach((e, i) => {
        !e.date ? delete this.tests[i].date : "";
      });
      this.$http
        .patch(
          this.baseUrl + "/clinic/hr/employee/editexamination",
          {
            userId: this.$route.params.id,
            examinations: this.tests,
            files: this.testsFiles,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this[loading] = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.getData();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this[loading] = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    uploadTestFile(file, index) {
      // NOTE (m-myUpload) to upload files
      this.isUploading[index] = true;
      this.myFile[index].showProgress = true;
      this.myFile[index].variant = "info";
      this.myFile[index].uploadPercentage = 0;
      this.myFile[index].isUploaded = false;
      this.myFile[index].uploadButDisable = true;
      var formData = new FormData();
      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl +
            "/clinic/hr/employee/uploadExamination?userId=" +
            this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile[index].uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 200) {
            this.myFile[index].isUploaded = true;
            this.myFile[index].uploadPercentage = 100;
            this.myFile[index].striped = false;
            this.myFile[index].variant = "success";
            this.myFile[index].uploadButDisable = false;
            this.tests[index].fileId = d.data.id;
            this.tests[index].fileUrl = d.data.url;
            this.isUploading[index] = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isUploading[index] = false;
          this.myFile[index].showProgress = false;
          this.myFile[index].variant = "info";
          this.myFile[index].uploadPercentage = 0;
          this.myFile[index].isUploaded = false;
          this.myFile[index].uploadButDisable = true;
        });
    },
    uploadFile(file, index) {
      // NOTE (m-myUpload) to upload files
      this.isFileUploading[index] = true;
      this.fileMyFile[index].showProgress = true;
      this.fileMyFile[index].variant = "info";
      this.fileMyFile[index].uploadPercentage = 0;
      this.fileMyFile[index].isUploaded = false;
      this.fileMyFile[index].uploadButDisable = true;
      var formData = new FormData();
      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl +
            "/clinic/hr/employee/uploadExamination?userId=" +
            this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.fileMyFile[index].uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 200) {
            this.fileMyFile[index].isUploaded = true;
            this.fileMyFile[index].uploadPercentage = 100;
            this.fileMyFile[index].striped = false;
            this.fileMyFile[index].variant = "success";
            this.fileMyFile[index].uploadButDisable = false;
            this.testsFiles[index].fileId = d.data.id;
            this.testsFiles[index].fileUrl = d.data.url;
            this.isFileUploading[index] = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isFileUploading[index] = false;
          this.fileMyFile[index].showProgress = false;
          this.fileMyFile[index].variant = "info";
          this.fileMyFile[index].uploadPercentage = 0;
          this.fileMyFile[index].isUploaded = false;
          this.fileMyFile[index].uploadButDisable = true;
        });
    },
  },
  computed: {
    computedDisabledForTests() {
      let result = false;
      this.tests.forEach((item) => {
        if (item.title == "") {
          result = true;
        } else {
          result = false;
        }
      });
      return result;
    },
    computedDisabledForFiles() {
      let result = false;
      this.testsFiles.forEach((item) => {
        if (item.fileId == "" || item.title == "") {
          result = true;
        } else {
          result = false;
        }
      });
      return result;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.employment-tests {
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
  }
  .date-input {
    .v-input__icon {
      border-radius: 12px !important;
    }
  }
}
</style>
